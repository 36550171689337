import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Post from "../components/post";

export const query = graphql`
  query EventTemplateQuery($id: String!) {
    event: sanityEvent(id: { eq: $id }) {
      id
      title
      subtitle
      poster {
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        caption
        source
      }
      category {
        slug {
          current
        }
        title
        color {
          hex
        }
      }
      tags {
        title
        slug {
          current
        }
      }
      posterYoutube
      _rawBody(resolveReferences: { maxDepth: 8 })
      address {
        title
        suburb
        streetNumber
        streetName
        state
        postcode
      }
      eventDates {
        end
        start
      }
      eventLink {
        title
        url
        platform
        buttonText
      }
      _rawEventInfo(resolveReferences: { maxDepth: 8 })
    }
  }
`;

const EventTemplate = ({ data, pageContext }) => {
  const relatedPosts = pageContext.relatedPosts;

  return (
    <Layout className="event" backgroundColor={data.event.category?.color.hex}>
      <Post
        post={data.event}
        relatedPosts={relatedPosts.slice(0, 3)}
        type="event"
      />
    </Layout>
  );
};

export default EventTemplate;
